<template>
  <div>
    <v-app-bar app outlined color="secondary" dark>
      <p class="ma-0">Mamnoon Mandoob</p>
      <v-spacer></v-spacer>
      <v-btn icon @click="Logout"><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { db } from "../db";

import { mapActions } from "vuex";
export default {
  data: () => ({}),
  methods: {
    ...mapActions({
      fetchUser: "fetchUser",
    }),
    Logout() {
      localStorage.removeItem("user");
      db.auth()
        .signOut()
        .then(() => {
          this.fetchUser(null);
          this.$router.replace({
            name: "auth",
          });
        });
    },
  },
};
</script>
