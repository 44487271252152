import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
// Get a Firestore instance
var config = {
    // apiKey: "AIzaSyAy0pxgLVRxpzTCSD65Kg6EWnqikFxtfKQ",
    // authDomain: "mamnon-tajer.firebaseapp.com",
    // projectId: "mamnon-tajer",
    // storageBucket: "mamnon-tajer.appspot.com",
    // messagingSenderId: "728530830905",
    // appId: "1:728530830905:web:4c9cbacdb581d7929befe8",
    // measurementId: "G-CSG22PF0PS"

    apiKey: "AIzaSyAPa3bDrslv9fSK9d4EvLmIeWPvRCltSro",
    authDomain: "mamnoon-3e764.firebaseapp.com",
    projectId: "mamnoon-3e764",
    storageBucket: "mamnoon-3e764.appspot.com",
    messagingSenderId: "1088855371754",
    appId: "1:1088855371754:web:a90b279ac357b19cdacc93",
    measurementId: "G-G4C9NMWZPY"



}
export const db = firebase.initializeApp(config)
db.auth().onAuthStateChanged(user => {
    console.log("Auth changed");
    console.log(user);
    if (user) {
        localStorage.setItem('user', JSON.stringify(user))
    }
    else {
        localStorage.removeItem('user')
    }
});
export var secondaryApp = firebase.initializeApp(config, "Secondary");


const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
