import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

import { firestorePlugin } from 'vuefire'
import imageCompression from "browser-image-compression";
Vue.use(imageCompression)

Vue.use(VueTelInputVuetify, {
  vuetify,
});
require('@/store/subscriber')
Vue.use(firestorePlugin)
store.dispatch('fetchUser', localStorage.getItem('userData')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})