import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,

    theme: {
        themes: {
            light: {
                secondary: "#333333", // #FFCDD2
                gold: "#B99503", // #FFCDD2
                dark_gold: "#AE821E", // #FFCDD2
            },
        },
    },

});
